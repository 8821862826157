.loginCard {
  width: 100%;
  height: 100%;
}

.loginCardGroup {
  width: 100%;
  height: 100%;
}

.loginCardContainer {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.loginImageContainer {
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6cadff;
  background-image: linear-gradient( to bottom, #6cadff, #8d88ff );
}

.loginImage {
  //height: 100%;
  //resize: "center";
}

.logoSmall {
  max-width: 100px;
  max-height: 100px;
  margin: auto;
}

.logo {
  max-width: 200px;
  max-height: 200px;
  margin: auto;
}

.fa-apple {
  color: white;
  background-color: gray;
}

.loading {
  position: absolute;
  top: 45%;
  z-index: 50;
}

@media only screen and (max-width: 576px) {
  .loginImageContainer {
    display: none;
  }
}


